import React from 'react';
import { Box, IconButton, Text, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
// And react-slick as our Carousel Lib
import Slider from 'react-slick';
import home_one from '../assets/home_one.jpg';
import home_to from '../assets/home_two.jpg';
import home_three from '../assets/home_three.jpg';

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function ImageGallery() {
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  // These are the images used in the slide
  const cards = [
    home_one, home_to, home_three
  ];

  return (
    <Box
      position={'sticky'}
      height={'xl'}
      width={'full'}
      alignContent={'center'}
      ml={16}
      overflow={'hidden'}>
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Text
        fontSize={isLargerThanLG ? '5xl' : '4xl'}
        fontWeight="bold"

        mt={50}
        mb={10}
      >
        Gallery
      </Text>
      {/* Slider */}
      <Slider {...settings} >
        {cards.map((url, index) => (
          <Box
            key={index}
            height={'xs'}
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundImage={`url(${url})`}
          />
        ))}
      </Slider>
    </Box>
  );
}