import {
  Box,
  Button,
  Flex,
  Img,
  Spacer,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import chakraHero from '../assets/transformer.jpg';
import Logo from './Logo';

const Hero = () => {
  const [isLargerThanLG] = useMediaQuery('(min-width: 52em)');
  return (
    <Flex

      bg='blackAlpha'
      w='full'
      px={isLargerThanLG ? '16' : '2'}
      py="16"
      justifyContent="space-between"
      flexDirection={isLargerThanLG ? 'row' : 'column'}
    >
      <Box mr={isLargerThanLG ? '6' : '0'}
           w={isLargerThanLG ? '50%' : 'full'}>
        <Logo/>
        <Spacer height={100} width={20}/>

        <Text
          fontSize={isLargerThanLG ? '5xl' : '4xl'}
          fontWeight="bold"
          mb="4"
        >
          {' '}
          About
        </Text>

        <Text mb="6" fontSize={isLargerThanLG ? 'lg' : 'base'} opacity={0.9}>
          Hamad Ali Alyami Co. for Maintenance is technical service provider and a leader in primary & secondary power distribution solutions with over 15 years of experience. Specializing in testing, repair and refurbishment of transformers, Unit sub-stations (USS), switch-gears, Ring Main Unit (RMU) & Breakers for utility, industrial and commercial applications in Saudi Arabia.
          Our team of engineers & technician are highly experienced and ready to provide expertise on site for testing, commissioning, maintenance, installation, oil filtration & repair services.
        </Text>

        <Button
          w="200px"
          colorScheme="blue"
          variant="solid"
          h="50px"
          size={isLargerThanLG ? 'lg' : 'md'}
          mb={isLargerThanLG ? '0' : '10'}
        /*  onClick={(e) => {
            e.preventDefault();
            window.location.href='https://blog.appseed.us/chakra-ui-react-coding-landing-page/';
          }}*/
        >
          Read More
        </Button>
      </Box>
      <Spacer />
      <Flex
        w={isLargerThanLG ? '40%' : 'full'}
        alignItems="center"
        justifyContent="center"
      >
        <Img
          borderRadius='10'
          src={chakraHero} alt="Chakra UI"  />
      </Flex>
    </Flex>
  );
};

export default Hero;