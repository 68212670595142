import * as React from "react";
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Nav from './components/Nav';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useRef } from 'react';
import DrawerComponent from './components/DrawerComponents';
import Clients from './components/Clients';
import ImageGallery from './components/ImageGallery';

function App() {
  /*const { isOpen, onOpen, onClose } = useDisclosure();*/
  /*const btnRef = useRef();*/
  return (
    <Box>
      {/*<Nav ref={btnRef} onOpen={onOpen} />*/}
      <Nav  />
      <Hero />
      <AboutUs />
      <Clients/>
      <ImageGallery/>
      {/* <Services />*/}
      {/*<Testimonials />*/}
     <ContactUs />
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <Footer />
    {/*  <DrawerComponent isOpen={isOpen} onClose={onClose} btnRef={btnRef} />*/}
    </Box>
  );
}

export default App