import React, { useState } from 'react';
import {
  Text,
  Flex,
  Spacer,
  IconButton,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { FaAlignJustify } from 'react-icons/fa';
import { Icon } from '@chakra-ui/react';
import Logo from './Logo';

const Nav = ({ onOpen, ref }) => {
  const [scroll, setScroll] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const navBg = useColorModeValue('white', 'blackAlpha.200');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  const changeScroll = () =>
    document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
      ? setScroll(true)
      : setScroll(false);

  window.addEventListener('scroll', changeScroll);

  return (
    <Flex
      h='10vh'
      alignItems='center'
      p='6'
      boxShadow={scroll ? 'base' : 'none'}
      top='4'
      w='full'
      bg={navBg}
    >

      {/*<Flex alignItems='center'>
        <Text fontSize='md' m='10'>About</Text>
        <Text fontSize='md'>More Apps</Text>
      </Flex>*/}

      <Flex alignItems='center'>
        <IconButton
          m='4'
          w={6}
          h={6}
          p={5}
          onClick={toggleColorMode}>
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </IconButton>

      </Flex>
    </Flex>
  );
};

export default Nav;