import { Flex, Image, Img, Spacer, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import one from '../assets/one.png';
import two from '../assets/two.jpg';
import three from '../assets/three.png';
import four from '../assets/four.png';
import five from '../assets/five.png';
import six from '../assets/six.png';

const Clients = () => {
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');

  const array = [
    {
      id: 1,
      text: ' Transformer',
      icon: one,
    },
    {
      id: 2,
      text: 'Unit Sub-Station(USS)',
      icon: two,
    },
    {
      id: 3,
      text: 'Ring Main Unit(RMU)',
      icon: three,
    },
    {
      id: 4,
      text: 'On-site services',
      icon: four,
    },
    {
      id: 5,
      text: 'Powder Coating',
      icon: five,
    },
    {
      id: 6,
      text: 'Industrial Oven',
      icon: six,
    },

  ];
  return (
    <Flex
      flexDirection={'column'}
    >
      <Text
        fontSize={isLargerThanLG ? '5xl' : '4xl'}
        fontWeight="bold"
        ml="16"
        mt={30}
      >
        Our Clients
      </Text>

  <Flex
      minH="30vh"
      alignItems="center"
      justifyContent="space-between"

      py="2"
      px={isLargerThanMD ? '16' : '6'}
      flexDirection={isLargerThanMD ? 'row' : 'column'}
    >
      {array.map((arr) => (
        <>

           {/* <Image as={arr.icon} boxSize={14} color="blue.600" mb="5" />*/}
            <Img
              borderRadius='4'
              boxSize={150}
              color={'blue.600'}
              m={5}
              src={arr.icon} alt="Chakra UI"  />

          <Spacer />
        </>
      ))}
    </Flex>

    </Flex>
  );
};

export default Clients