import { Flex, Text, Link, Image, useMediaQuery, Divider, ButtonGroup, IconButton, space } from '@chakra-ui/react';
import React from 'react';
import scanMe from '../assets/scan_me.png';
import { FaFacebook, FaGithub, FaLinkedin, FaTwitter } from 'react-icons/all';
import logo from '../assets/logo.png';
import cert1 from '../assets/certificate_one.jpg';
import cert2 from '../assets/certificate_two.jpg';
import cert3 from '../assets/certificate_three.jpg';

const Footer = () => {
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  return (
    <Flex
      w='full'
      bg='blackAlpha'
      minHeight='20vh'
      flexDirection={isLargerThanLG ? 'row' : 'column'}
      textAlign='center'
      justifyContent='space-evenly'>
      <Flex
        align={'top'}
        mt={10}
        justifyContent={'center'}
        flexDirection={'row'}>

        <Image
          boxSize={'100px'}
          src={logo}
          alt='Logo Company'
          objectFit={'cover'} />
        <Flex
          flexDirection={'column'}
          alignItems={'stretch'}
          m={5}>
          <Text fontSize='sm' fontWeight='bold' color='green.600'>
            شركة حمد علي اليامي للصيانة
          </Text>
          <Text fontSize='sm' fontWeight='bold' color='green.600'>
            Hamad Ali Alyami Co. for Maintenance
          </Text>
        </Flex>

      </Flex>
      <Flex
        flexDirection='column'>
        <Text mt='30' mb={10} fontSize={'large'}>
        </Text>
        <Text opacity='0.7'>At Taawun, 2641 5c
          Al Khobar, Saudi Arabia
        </Text>
        <Text opacity='0.7'>
          +966 13 864 2701
        </Text>
        <Text opacity='0.7' mb={10}>
          info@hamadalyami.com
        </Text>
      </Flex>
      <Flex
        flexDirection='column'
        align={'center'}
        m={10}>
        <Flex
          flexDirection={'row'}
          align={'center'}>
        <Image
          boxSize={'100px'}
          src={cert1}
          alt='Scan Company'
          objectFit={'scale-down'} />
        <Image
          boxSize={'100px'}
          src={cert2}
          alt='Scan Company'
          objectFit={'contain'} />
          <Image
            boxSize={'100px'}
            src={cert3}
            alt='Scan Company'
            objectFit={'contain'} />
        </Flex>

        <ButtonGroup variant="ghost"
                     mt={10}>
          <IconButton
            as="a"
            href="https://www.linkedin.com/company/75069088"
            aria-label="LinkedIn"
            icon={<FaLinkedin fontSize="1.25rem" />}
          />
          <IconButton as="a" href="https://www.facebook.com/HAYCOsaudia" aria-label="GitHub" icon={<FaFacebook fontSize="1.25rem" />} />
          <IconButton as="a" href="https://twitter.com/HAYCOsaudia" aria-label="Twitter" icon={<FaTwitter fontSize="1.25rem" />} />
        </ButtonGroup>

        <Text mt='5'>
          ©2022 Provided by{' '}
          <Link href='https://easysoft123.com' isExternal color='blue.500'>
            EasySoft
          </Link>
        </Text>


      </Flex>



    </Flex>
  );
};

export default Footer;