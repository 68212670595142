import React from 'react';
import { Box, Flex, Image, Img, Spacer, Text, useMediaQuery } from '@chakra-ui/react';
import logoCompany from '../assets/logo.png';


export default function Logo(props) {
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');

  return (
    <Flex w={'full'}
          align={'center'}
          flexDirection={'row'}>

      <Image boxSize={'100px'} src={logoCompany} alt='Logo Company' objectFit={'cover'} />
      <Flex flexDirection={'column'} m={5}>
        <Text
          fontSize='lg'
          fontWeight='bold'
          color='green.600'>
          شركة حمد علي اليامي للصيانة
        </Text>
        <Text
          fontSize='xl'
          fontWeight='bold'
          color='green.600'>
          Hamad Ali Alyami Co. for Maintenance
        </Text>
      </Flex>

    </Flex>
  );
}
