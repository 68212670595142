import { Flex, Image, Img, Spacer, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import transformer from '../assets/transformer1.jpg';
import uus from '../assets/uss.png';
import rmu from '../assets/rmu.jpg';
import onSite from '../assets/on_site_service.jpg';
import powder from '../assets/powder_cowding.jpg';
import industrial from '../assets/industrial_oven.jpg';
import sand from '../assets/sandblasting.jpg';

const AboutUs = () => {
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');

  const array = [
    {
      id: 1,
      text: ' Transformer',
      icon: transformer,
    },
    {
      id: 2,
      text: 'Unit Sub-Station(USS)',
      icon: uus,
    },
    {
      id: 3,
      text: 'Ring Main Unit(RMU)',
      icon: rmu,
    },
    {
      id: 4,
      text: 'On-site services',
      icon: onSite,
    },
    {
      id: 5,
      text: 'Powder Coating',
      icon: powder,
    },
    {
      id: 6,
      text: 'Industrial Oven',
      icon: industrial,
    },
    {
      id: 7,
      text: 'Sand Blasting',
      icon: sand,
    },
  ];
  return (
    <Flex
      flexDirection={'column'}
    >
      <Text
        fontSize={isLargerThanLG ? '5xl' : '4xl'}
        fontWeight="bold"
        ml="16"
        mt={16}
      >
        Our Services
      </Text>

  <Flex
      minH="30vh"
      alignItems="center"
      justifyContent="space-between"
      w="full"
      py="2"
      px={isLargerThanMD ? '16' : '6'}
      flexDirection={isLargerThanMD ? 'row' : 'column'}
    >
      {array.map((arr) => (
        <>
          <Flex
            height="200px"
            bg="blackAlpha.200"
            width={isLargerThanMD ? '13%' : 'full'}
            shadow="md"
            p="2"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            flexDirection="column"
            textAlign="center"
            mb={isLargerThanMD ? '2' : '4'}
            border="1px solid #C4DDFF"
          >
           {/* <Image as={arr.icon} boxSize={14} color="blue.600" mb="5" />*/}
            <Img
              borderRadius='10'
              boxSize={100}
              color={'blue.600'}
              mb={5}
              src={arr.icon} alt="Chakra UI"  />
            <Text>{arr.text}</Text>
          </Flex>

          <Spacer />
        </>
      ))}
    </Flex>

    </Flex>
  );
};

export default AboutUs